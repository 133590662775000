import React, { useState } from "react";
import "./index.css";

const Carousel = ({ children }) => {
  const [slide, _setSlide] = useState(1);
  const [animated, setAnimated] = useState(false);

  const slideWidth = 800;

  const _children = React.Children.toArray(children);
  const slidesCount = _children.length + 2;

  const setSlide = (i) => {
    _setSlide((s) => {
      setAnimated(true);

      let res = s + i;
      const maxChild = slidesCount - 1;

      if (slide === 0) {
        _setSlide(() => {
          setAnimated(false);
          return maxChild - 1;
        });
      }

      if (slide === maxChild) {
        _setSlide(() => {
          setAnimated(false);
          return 1;
        });
      }

      return res;
    });
  };

  if (_children.length === 0) return null;

  const slides = [
    _children[_children.length - 1], // Хвост в нос
    ..._children,
    _children[0]                     // Нос в хвост
  ];

  return (
    <div className="carousel">
      <div
        className={
          "carousel-wrapper" + (animated ? " carousel-wrapper_animated" : "")
        }
        onTransitionEnd={() => {
          setAnimated(false);
        }}
        style={{
          width: slidesCount * slideWidth + "px",
          transform: `translateX(${slide * slideWidth * -1}px)`,
        }}
      >
        {slides.map((child, index) => {
          if (
            animated
              ? index >= slide - 1 && index <= slide + 1
              : index === slide
          ) {
            return React.cloneElement(child, {
              key: index,
              style: {
                border: "2px solid red",
                boxSizing: "border-box",
                width: "800px",
                height: "600px",
                position: "absolute",
                top: 0,
                left: index * slideWidth + "px",
              },
            });
          }
          return null;
        })}
      </div>
      <div
        className="carousel__button carousel__button_left"
        onClick={() => {
          if (!animated) setSlide(-1);
        }}
      >
        &lt;
      </div>
      <div
        className="carousel__button carousel__button_right"
        onClick={() => {
          if (!animated) setSlide(1);
        }}
      >
        &gt;
      </div>
    </div>
  );
};

export default Carousel;

import React, { useState } from "react";
import Carousel from './carousel';

const App = () => {
  const [items] = useState((new Array(6)).fill(null));
  return (
    <div>
      <Carousel>
        {items.map((item, index) => (
          <div key={index}>
            <h1>{index}</h1>
            <h2>Example {index} slide</h2>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default App;
